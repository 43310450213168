import './App.css';
import { Facebook, Twitter, Mail } from '@mui/icons-material';
import { useState } from 'react';

function App() {
  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener('resize', () => {
    setWidth(window.innerWidth);
  });

  return (
    <div className="App">
      <div className="header">
        <div className="logoAndButtonsContainer">
          <a href="https://www.ubastimodels.com">
            <div className="logoContainer">
              <img className="topLogo" src="./ubastibackgroundlogo.png" alt="ubastiModelsLogo"/>
            </div>
          </a>
          {width > 700 && <div className="buttonContainer">
            <div className="buttons" onClick={()=>null}>WHAT IT DOES</div>
            <div className="buttons" onClick={()=>null}>AMBASADORS</div>
            <div className="buttons" onClick={()=>null}>TOKEN UTILITY</div>
          </div>}
        </div>
      </div>
      <div className="mainContainer">
        <div className="Message">
          <p className="firstLine">
          The Closing Chapter of <span className="companyName">Ubasti Models</span>...
          </p>
          <p className="mainParagragh">
            We would like to express our deepest gratitude for your loyal support throughout our journey. It is with a heavy heart that we announce the permanent closure of our business. We are incredibly grateful for the trust you placed in us, the memories we shared, and the relationships we built. While this chapter may be coming to a close, the cherished moments and connections will forever hold a special place in our hearts. Thank you for being a part of our story.
          </p>
          <p className="firstLine">
            Former CEO, Ubasti Models
          </p>
        </div>
        <div className="enquiryButtonContainer">
          <a className="enquiryButton" href="mailto:hr@ubastimodels.com">
            <Mail style={{position: "relative", top: width > 500 ? "0.3vh" : "0.4vh", fontSize: "2vh"}}/>
            <span>&#160;</span> Enquiries
          </a>
        </div>
      </div>
      <div className="footerContainer">
        <div className="socialsContainer">
          <div className="socials">
            <div style={{marginRight: "20px", padding: 7}}>
              <a href="https://twitter.com/ubastimodels">
                <Twitter className="socialLogos"/> 
              </a>
            </div>
            <div style={{marginLeft: "20px", padding: 7}}>
              <a href="https://www.facebook.com/ubastimodels">
                <Facebook className="socialLogos"/>
              </a>
            </div>
          </div>  
        </div>
        <div style={{position: "relative", bottom: 10, fontSize: 9, paddingTop:6}}>
          CopyRight by Ubasti LLC
        </div>
      </div>
    </div>
  );
}

export default App;
